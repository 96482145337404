import { Link } from '@/navigation'
import useMeta from '@/store/meta'
import { useTranslations } from 'next-intl'
import SCP_CoinIcon from '../widgets/CoinIcon'

export default function SCP_SnackbarPointGuest() {
  const __t = useTranslations()
  const { metaSettings } = useMeta()
  const pointsToEarn = metaSettings?.fpd_ot_register_point_to_earn

  return (
    <div className="tw-flex tw-items-center    tw-space-x-2">
      <div className="tw-flex tw-items-center    tw-space-x-2">
        <div className="tw-relative">
          <SCP_CoinIcon level="min" />
          <div className="tw-absolute tw-rounded-full tw-mix-blend-color tw-top-0 tw-w-6 tw-h-6 tw-bg-bgh-dark tw-z-10"></div>
        </div>
        <span>{__t('register-now-for-points', { points: pointsToEarn })}</span>
      </div>
      <Link
        prefetch={false}
        href="/auth/login"
        className="tw-flex-none hover:tw-bg-white/10 tw-duration-200 tw-px-2.5 tw-py-0.5 tw-h-min tw-border tw-border-white/50 tw-rounded-full"
      >
        {__t('sign-up')}
      </Link>
    </div>
  )
}
