import { Link } from '@/navigation'
import getPostImage from '@/utils/post/getPostImage'
import getPostTitle from '@/utils/post/getPostTitle'
import { Skeleton } from '@mui/material'
import SCP_CircleIcon from '../element/CircleIcon'
import CCP_TransitionImageChild from '../element/TransitionImageChild'

const CenterCard = ({
  isLoading = false,
  post,
  currentCenterGroup,
  target = '_self',
  isShortTitle = false,
}) => {
  let title = getPostTitle(post, isShortTitle)
  let image = getPostImage(post, 'medium')
  function getPath() {
    return post?.redirect
      ? post?.redirect
      : currentCenterGroup
        ? `/center-clinic/${currentCenterGroup._slug}/${post._slug}/overview`
        : '#'
  }

  return (
    <Link
      prefetch={false}
      target={target}
      href={getPath()}
      className={`tw-shadow-main-blue tw-rounded-md tw-p-4 tw-grid tw-grid-cols-3 tw-gap-4 tw-group hover:tw-scale-[102%] tw-duration-200 tw-bg-white tw-cursor-pointer ${
        isLoading ? 'tw-pointer-events-none' : ''
      }`}
    >
      <div className="tw-col-span-1 tw-relative tw-w-full tw-pb-fb  tw-rounded-md tw-overflow-hidden tw-shadow-main-blue-light">
        {isLoading ? (
          <Skeleton
            variant="rounded"
            className="!tw-absolute !tw-w-full !tw-h-full"
          />
        ) : (
          <CCP_TransitionImageChild alt="Center Image" src={image} />
        )}
      </div>
      <div className="tw-col-span-2 tw-flex tw-items-center    tw-space-x-2 tw-justify-between">
        {isLoading ? (
          <>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="circular" width={32} height={32} />
          </>
        ) : (
          <>
            <h6 className="tw-line-clamp-2 group-hover:tw-text-bgh-blue tw-duration-200">
              {title}
            </h6>
            <SCP_CircleIcon icon="far fa-arrow-right rtl:tw-rotate-180" />
          </>
        )}
      </div>
    </Link>
  )
}

export default CenterCard
