'use client'
import { ListItemButton } from '@mui/material'
import { useLocale } from 'next-intl'
import LanguageSwitcherListItem from './LanguageSwitcherListItem'

const LanguageSwitcher = () => {
  const currentLocale = useLocale()
  return (
    <div className="tw-group tw-relative tw-h-full ">
      <ListItemButton component="div" className="menu-button tw-h-full tw-px-6">
        <h6 className="group-hover:tw-text-bgh-blue tw-duration-200 tw-uppercase">
          {currentLocale}
        </h6>
      </ListItemButton>
      <div className="tw-opacity-0 tw-pointer-events-none -tw-translate-y-4 group-hover:tw-opacity-100 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-0 tw-duration-200 group-hover:after:tw-translate-y-0 after:tw-translate-y-[12px] after:tw-duration-200 after:tw-delay-200 tw-absolute tw-top-[calc(100%+1px)] tw-w-[200px] tw-py-2 tw-bg-bgh-white tw-rounded-md tw-shadow-submenu after:tw-absolute after:tw-border-b-[12px] after:tw-border-x-[12px] after:tw-border-t-0 after:tw-border-b-bgh-white after:tw-border-x-transparent after:tw-top-[-12px] ltr:tw-right-[-50px] ltr:after:tw-right-[60px] rtl:tw-left-[-50px] rtl:after:tw-left-[60px]">
        <LanguageSwitcherListItem />
      </div>
    </div>
  )
}

export default LanguageSwitcher
