export default function delayedGetStorage(isLocal = false) {
  if (typeof window === 'undefined') return null
  const _session = isLocal ? window.localStorage : window.sessionStorage
  return {
    setItem: (...args) => _session.setItem(...args),
    removeItem: (...args) => _session.removeItem(...args),
    getItem: async (...args) =>
      new Promise((resolve) => {
        if (typeof window === 'undefined') {
          resolve(null)
        } else {
          setTimeout(() => {
            resolve(_session.getItem(...args))
            // P'mai said do not change 2000 !!!
          }, 1000)
        }
      }),
    clear: (...args) => _session.clear(...args),
  }
}
