'use client'
import useAuth from '@/store/auth'
import { useTranslations } from 'next-intl'
import { useSnackbar } from 'notistack'
import { useRef } from 'react'
import SCP_SnackbarPointGuest from '../snackbar/SnackbarPointGuest'
import SCP_SnackbarPointLogin from '../snackbar/SnackbarPointLogin'
import SCP_SnackbarPointReward from '../snackbar/SnackbarPointReward'
import useGlobal from '@/store/global'

export default function CCP_SnackbarPointWrapper() {
  const __t = useTranslations()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const eventIdRef = useRef(null)
  const { userMe } = useAuth()
  const { isShowGuestSnackbar } = useGlobal()
  const onCloseSnackbar = async () => {
    closeSnackbar()
    useGlobal.setState({
      isShowGuestSnackbar: false,
    })
    await useAuth.getState().getUserMe()
  }
  // guest
  if (isShowGuestSnackbar) {
    enqueueSnackbar(<SCP_SnackbarPointGuest />, {
      className: 'bg-gradient-fpd-gray',
      action: () => (
        <i
          className="fa fa-times snackbar-close-icon"
          onClick={() => onCloseSnackbar()}
        />
      ),
    })
  }
  // login user
  const recentEvent = userMe?.my_b_point?.recent_event
  if (!recentEvent) return null
  // Point snackbar
  const recentEventsSlug = recentEvent.event_name?.toLowerCase()
  const recentEventPoints = recentEvent.point_earned
  const recenterEventsText = __t(`dialog_${recentEventsSlug}`, {
    points: recentEventPoints,
  })
  // earn points
  if (recentEventPoints > 0 && eventIdRef.current !== recentEvent.event_id) {
    enqueueSnackbar(<SCP_SnackbarPointLogin text={recenterEventsText} />, {
      className: 'bg-gradient-fpd-blue-main',
      action: () => (
        <i
          className="fa fa-times snackbar-close-icon"
          onClick={() => onCloseSnackbar()}
        />
      ),
    })
    eventIdRef.current = recentEvent.event_id
  }

  function testSnackbar() {
    enqueueSnackbar(
      <SCP_SnackbarPointReward text={__t('reward-successfully-redeemed')} />,
      {
        className: '!tw-bg-bgh-fpd-green',
        action: () => (
          <i
            className="fa fa-times snackbar-close-icon"
            onClick={() => closeSnackbar()}
          />
        ),
      }
    )
  }

  return null
}
