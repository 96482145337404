import { useTranslations } from 'next-intl'

export default function SCP_SnackbarPointReward({
  text = '',
  onClick = () => {},
}) {
  const __t = useTranslations()
  return (
    <div className="tw-flex tw-items-center    tw-space-x-2">
      <div className="tw-flex tw-items-center    tw-space-x-2">
        <i className="fal fa-check-circle tw-flex-none tw-text-lg" />
        <span>{text}</span>
      </div>
      {onClick ? (
        <div
          onClick={onClick}
          className="tw-cursor-pointer tw-flex-none hover:tw-bg-white/10 tw-duration-200 tw-px-2.5 tw-py-0.5 tw-h-min tw-border tw-border-white/50 tw-rounded-full"
        >
          {__t('view')}
        </div>
      ) : null}
    </div>
  )
}
