'use client'
import { useLocale } from 'next-intl'
import { usePathname } from '@/navigation'
import { useEffect, useState } from 'react'

const AnnouncementWrapper = ({ children }) => {
  const locale = useLocale()
  const pathname = usePathname()
  const [isShow, setIsShow] = useState(false)
  // Render condition
  useEffect(() => {
    if (pathname === '/' + locale || pathname === '/') {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [pathname])

  return (
    <div
      className={`tw-bg-bgh-blue-fade tw-relative tw-flex tw-items-center tw-space-x-6 tw-duration-200 tw-px-4 ${
        isShow
          ? 'tw-py-4 tw-opacity-100'
          : '-tw-translate-y-full tw-h-0 tw-opacity-0'
      }`}
    >
      {/* Info */}
      {children}
      {/* Close */}
      <i
        onClick={(prev) => setIsShow(!prev)}
        className="fa fa-times  tw-text-bgh-blue hover:tw-scale-110 tw-duration-200 tw-cursor-pointer"
      />
    </div>
  )
}

export default AnnouncementWrapper
