'use client'

import { Skeleton } from '@mui/material'
import Image from 'next/image'
import { useState } from 'react'

export default function CCP_TransitionImageChild({
  src,
  alt = 'Content Image',
  addedClass,
  transition = 'scale',
  scaleY = 'tw-scale-y-[200%]',
  priority = false,
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  let transitionClass = ''
  let transitionFrom = `tw-opacity-0 ${scaleY}`
  let transitionTo = 'tw-opacity-100 tw-scale-y-100'
  if (transition === 'object-position') {
    transitionFrom = 'tw-opacity-0 tw-object-center'
    transitionTo = 'tw-opacity-100 tw-object-top'
  }
  return (
    <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-overflow-hidden">
      <Image
        alt={alt}
        priority={priority}
        src={src}
        fill={true}
        className={`tw-object-cover !tw-top-0 !tw-left-0 tw-duration-300 tw-delay-[50ms] ${transitionClass} ${addedClass} ${
          isLoaded ? transitionTo : transitionFrom
        }`}
        onLoad={(img) => setIsLoaded(true)}
      />
      <Skeleton
        variant="rounded"
        width="100%"
        height="100%"
        className={`!tw-absolute !tw-top-0 !tw-left-0 !tw-duration-500 ${
          isLoaded ? 'tw-opacity-0' : 'tw-opacity-100'
        }`}
      />
    </div>
  )
}
