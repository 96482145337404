import { Skeleton } from '@mui/material'
import CCP_ImageFallback from '../element/ImageFallback'

const IconCardSmall = ({
  isLoading,
  icon,
  iconSrc = 'https://static.bangkokhospital.com/uploads/2019/11/Brain2.svg',
  title = 'Bone & Spine',
}) => {
  return (
    <div
      className={`tw-cursor-pointer tw-w-full tw-h-full tw-bg-white tw-shadow-main-blue tw-p-3 tw-rounded-lg tw-flex  tw-flex-row  tw-space-x-2 tw-items-center tw-group hover:tw-scale-[102%] tw-duration-200 ${
        isLoading ? 'tw-pointer-events-none' : ''
      }`}
    >
      {isLoading ? (
        <Skeleton variant="rounded" width={64} height={64} />
      ) : (
        <div className="tw-size-12 tw-p-3 tw-bg-bgh-blue-alpha tw-rounded-md tw-relative tw-flex-none">
          {icon ? (
            <i className={`${icon} tw-text-2xl tw-text-primary`} />
          ) : (
            <CCP_ImageFallback
              src={iconSrc}
              fill={true}
              alt="Center & Clinic Icon"
              className=" tw-p-2 tw-object-contain"
            />
          )}
        </div>
      )}
      {isLoading ? (
        <>
          <Skeleton variant="text" width="80%" />
        </>
      ) : (
        <>
          <h5 className="group-hover:tw-text-bgh-blue sm:tw-text-base tw-text-sm tw-duration-200">
            {title}
          </h5>
        </>
      )}
    </div>
  )
}

export default IconCardSmall
