export default function getPostTitle(post, isShortTitle = false) {
  if (!post) return ''
  if (isShortTitle) {
    return post.short_title || post.title
  }
  if (post.full_title) {
    return post.full_title
  } else if (post.title) {
    return post.title
  } else {
    return post._title
  }
}
