export default function getPostImage(
  post,
  size = '',
  name = '',
  hasVideo = false
) {
  if (!post) return '/images/fallback.svg'
  if (name && post[name] && post[name].url) {
    return post[name].url
  } else if (post.image) {
    return post.image.url ? post.image.url : post.image
  } else if (post.cover_image) {
    return size
      ? post.cover_image?.sizes?.[size]?.url || post.cover_image?.url
      : post.cover_image?.url
  } else if (post.cover_image_url) {
    return post.cover_image_url
  } else if (post.icon) {
    return post.icon.url ? post.icon.url : post.icon
  } else if (post.logo) {
    return post.logo.url
  } else if (post.cover_image_url) {
    return post.cover_image_url
  } else if (post.poster) {
    return post.poster.url
  } else {
    return hasVideo
      ? 'https://static.bangkokhospital.com/uploads/2024/04/videocoverimage.jpg'
      : 'https://static.bangkokhospital.com/uploads/2024/04/backupcoverimg.jpg'
  }
}
