'use client'
import { usePathname } from '@/navigation'
import shouldShowCtaHideColorBar from '@/utils/navbar/shouldShowCtaHideColorBar'

export default function CCP_ColorBar({ isFooter = false }) {
  const pathname = usePathname()

  return (
    <div
      className={`main-gradient tw-justify-between tw-transition-all tw-duration-700 tw-ease-bezier tw-p-0 tw-mb-0 tw-h-[5px] ${shouldShowCtaHideColorBar(pathname) && !isFooter ? 'tw--mt-[5px] tw-mb-[32px] tw-scale-x-0' : 'tw-scale-x-1'}`}
    />
  )
}
