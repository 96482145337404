'use client'
import Image from 'next/image'
import { useEffect, useState } from 'react'

export default function CCP_ImageFallback({ ...props }) {
  const { src, fallbackSrc = '/images/fallback.svg', ...rest } = props
  const [imgSrc, setImgSrc] = useState(src)
  useEffect(() => {
    setImgSrc(src)
  }, [src])
  return (
    <Image
      {...rest}
      src={imgSrc}
      sizes="100%"
      onError={() => {
        setImgSrc(fallbackSrc)
      }}
    />
  )
}
