import { useLocale, useTranslations } from 'next-intl'
import SCP_CoinIcon from './CoinIcon'
import useMeta from '@/store/meta'
import onlyThEn from '@/utils/onlyThEn'

export default function SCP_CoinInfo() {
  const { metaSettings } = useMeta()
  const locale = useLocale()
  const info = metaSettings[`fpd_description_${onlyThEn(locale)}`]
  const __t = useTranslations()
  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-space-y-6 tw-py-4">
      <SCP_CoinIcon level="info" />
      <div className="tw-flex tw-items-center    tw-space-x-2 tw-text-bgh-dark [&_h3]:!tw-text-bgh-dark">
        <h3 className="tw-w-full tw-flex">
          {__t.rich('about-point-title', {
            icon: (
              <SCP_CoinIcon key="point-icon" level="min" className="tw-mx-2" />
            ),
          })}
        </h3>
      </div>
      <hr className="tw-w-full" />
      <div
        className="entry-content [&_h4]:!tw-text-bgh-dark [&_h4:not(:first-of-type)]:!tw-pt-0
 tw-w-full"
        dangerouslySetInnerHTML={{ __html: info }}
      />
    </div>
  )
}
