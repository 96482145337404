const defaultForm = {
  appointment_service: '',
  doctor_selection_method: '',
  doctor_specialty_selection_method: '',
  //doctor_info
  doctor_specialty: '',
  doctor_name: '',
  doctor_slug: '',
  //appointment_info
  medical_info: '',
  preferred_date_source: '',
  preferred_date: '',
  preferred_time: '',
  document_files: [],
  document_file_1: '',
  document_file_2: '',
  document_file_3: '',
  //contact_info
  //patient_info
  title: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  email: '',
  phone_number: '',
  date_of_birth: '',
  date_of_birth_source: '',
  gender: '',
  id: '',
  nationality: '',
  //contact_person_info
  contact_person_name: '',
  contact_person_phone_number: '',
  contact_person_email: '',
}

const defaultRealtimeForm = {
  Note: null,
  Service: null,
  HN: null,
  Location: null,
  SlotID: null,
  Application: 'BHQWEBSITE',
  Doctor: null,
  doctor_name: null,
  doctor_slug: null,
  doctor_center: null,
  doctor_service_label: null,
  Date: null,
  Time: null,
  preferred_date_source: null,
  preferred_time_source: null,
  selected_center_service: null,
  selected_center_service_label: null,
  selected_center_service_location: null,
  selected_center_service_condition_01: null,
  selected_center_service_condition_01_label: null,
  selected_center_service_instruction: null,
  selected_center_service_is_exceptional_non_realtime: false,
  custom_field_0: null,
  custom_field_1: null,
  custom_field_2: null,
  custom_field_3: null,
  custom_field_4: null,
}


export { defaultForm, defaultRealtimeForm }