import axios from 'axios'

axios.defaults.withCredentials = true
const timeout = 45000

const NEXT_INSTANCE = axios.create({
  baseURL: '/',
  timeout,
})

// Create axios instance
const CMS_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout,
  withCredentials: true,
})

const VIP_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_VIP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout,
  withCredentials: true,
})

const EPMS_INSTANCE = axios.create({
  baseURL: process.env.EPMS_BASE_URL,
  headers: {
    Authorization: `Token ${process.env.EPMS_TOKEN}`,
    Accept: 'application/json',
  },
})

const EPMS_QA_INSTANCE = axios.create({
  baseURL: process.env.EPMS_QA_BASE_URL,
  headers: {
    Authorization: `Token ${process.env.EPMS_TOKEN}`,
    Accept: 'application/json',
  },
})

// Shared data [TEMPORARY]
const SHARED_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SHARED_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout,
  withCredentials: true,
})

// META data [TEMPORARY]
const META_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_META_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout,
  withCredentials: true,
})

// CMS data [TEMPORARY]
const OLD_CMS_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CMS_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout,
  withCredentials: true,
})

CMS_INSTANCE.interceptors.request.use((config) => {
  config.metadata = { startTime: new Date() }
  // if config.url has https:// or http://, don't add baseURL
  if (config.url.includes('https://')) {
    config.baseURL = ''
  }
  return config
})

CMS_INSTANCE.interceptors.response.use(
  (response) => {
    // Log response time
    const duration = new Date() - response.config.metadata.startTime
    console.info(
      `\x1b[93m[API]\x1b[0m ${response.config.method?.toLocaleUpperCase()} ${response.config.baseURL
      }/${response.config.url} - \x1b[32m${duration}ms\x1b[0m`
    )
    return response
  },
  (error) => {
    // Log error
    console.error(
      `\x1b[93m[API]\x1b[0m ${error.config.method?.toLocaleUpperCase()} ${error.config.baseURL
      }/${error.config.url} - ${error.message}`
    )
    return Promise.reject(error)
  }
)

export {
  CMS_INSTANCE,
  VIP_INSTANCE,
  EPMS_INSTANCE,
  EPMS_QA_INSTANCE,
  META_INSTANCE,
  NEXT_INSTANCE,
  OLD_CMS_INSTANCE,
  SHARED_INSTANCE,
}

