export default function modifyUserMenu(menu, userMe) {
  let modifiedMenu = menu
  if (userMe?.vip_tier) {
    //add vip menu after menu with activeSlug = 'profile'
    modifiedMenu = menu.reduce((acc, item) => {
      if (item.activeSlug === 'profile') {
        return [
          ...acc,
          item,
          {
            title: userMe?.vip_tier,
            path: `/beyond/auth/login?method=${userMe?.register_channel}&login=${userMe?.username}`,
            icon: 'far fa-credit-card-blank',
            activeSlug: 'vip',
            target: '_blank',
          },
        ]
      }
      return [...acc, item]
    }, [])
  }
  return modifiedMenu
}