'use client'
import { usePathname } from '@/navigation'
import useMeta from '@/store/meta'
import { useLocale } from 'next-intl'
import { useSearchParams } from 'next/navigation'

const LanguageSwitcherListItem = ({ showOnly = [] }) => {
  const currentLocale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  let locales = useMeta((state) => state?.metaInfo?.locales) || []

  if (showOnly.length) {
    locales = locales.filter((locale) => showOnly.includes(locale.value))
  }

  return (
    <ul>
      <li className="tw-px-3 tw-py-1 tw-text-sm tw-opacity-50">
        Change Language
      </li>
      {locales.map((locale, i) => (
        <li key={`locale-wrapper-${i}`} className="tw-border-t">
          <a
            aria-label={`Change language to ${locale.label}`}
            href={`${locale?.value == 'th' ? '' : `/${locale?.value}`}${pathname}${searchParams.size ? '?' + searchParams.toString() : ''}`}
            className={`tw-flex tw-items-center tw-space-x-3 tw-px-3 tw-py-1.5 tw-duration-200 tw-text-primary ${
              currentLocale === locale.value
                ? 'tw-bg-primary/10 tw-pointer-events-none tw-opacity-20'
                : 'hover:tw-bg-bgh-blue-alpha/50'
            }`}
          >
            <div>
              <div className="tw-font-bold">{locale.label}</div>
              <span className="tw-uppercase tw-text-xs">{locale.value}</span>
            </div>
          </a>
        </li>
      ))}
    </ul>
  )
}

export default LanguageSwitcherListItem
