'use client'
import { Link } from '@/navigation'
import { useEffect, useState } from 'react'

const ToTop = () => {
  const [toTopSticky, setToTopSticky] = useState(false)
  const [socialSticky, setSocialSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setSocialSticky(window.scrollY > 0)
      setToTopSticky(window.scrollY > 800)
    }

    if (process.browser) {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getUrl = () => {
    const locale = process.browser
      ? window?.navigator?.language?.split('-')[0]
      : null

    // Get the current time in UTC
    const nowUTC = new Date()
    // Calculate the offset for Bangkok time zone (+7 hours)
    const bangkokOffset = 7 * 60 // 7 hours in minutes
    // Get the time in Bangkok by adding the offset to the current UTC time
    const bangkokTime = new Date(nowUTC.getTime() + bangkokOffset * 60 * 1000)
    // Extract hours and minutes from the Bangkok time
    const hours = bangkokTime.getUTCHours()
    const minutes = bangkokTime.getUTCMinutes()
    const isOutsideWorkingHours =
      hours > 20 || hours < 8 || (hours === 20 && minutes > 0)

    if (!isOutsideWorkingHours) {
      return 'https://www.bangkokhospital.com/chat/index.html'
    }
    switch (locale) {
      case 'th':
        return 'https://bkkhos.com/chatBHQLINE'
      case 'en':
        return 'https://bkkhos.com/chatBHQTHMsg'
      case 'zh':
        return 'https://bkkhos.com/chatBHQLINE'
      case 'ja':
        return 'https://bkkhos.com/chatBHQJPLINE'
      case 'km':
        return 'https://bkkhos.com/chatBHQTHMsg'
      case 'ar':
        return 'https://bkkhos.com/chatBHQWA'
      case 'my':
        return 'https://bkkhos.com/chatBHQMYMsg'
      default:
        return 'https://bkkhos.com/chatBHQTHMsg'
    }
  }

  return (
    <div
      id="sticky-button-wrapper"
      className="tw-fixed tw-right-0 tw-bottom-16 tw-z-10 tw-grid tw-gap-2"
    >
      {/* Scroll to top */}
      <div
        id="to-top-wrapper"
        className={`tw-rounded-l-md tw-bg-bgh-translucent tw-backdrop-blur-[5px] tw-size-14 tw-border tw-border-black/5 tw-flex tw-items-center tw-justify-center hover:tw-border-bgh-blue tw-duration-500 tw-ease-bezier tw-cursor-pointer tw-group ${
          toTopSticky
            ? 'tw-translate-y-0 tw-opacity-100'
            : 'tw-translate-y-14 tw-opacity-0'
        }`}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <i
          className="fa fa-arrow-up tw-text-bgh-blue group-hover:-tw-translate-y-0.5 tw-duration-200"
          id="to-top-icon"
        />
      </div>
      {/* Contacts Floating Button */}
      <Link
        prefetch={false}
        id="chat-bubble-wrapper"
        href={getUrl()}
        data-bhq-cta="CLICK_CHAT_BUBBLE"
        target="_blank"
        className={`tw-rounded-l-md tw-bg-primary tw-size-14 tw-flex tw-flex-col tw-space-y-1 tw-items-center tw-justify-center hover:tw-bg-bgh-blue tw-duration-500 tw-cursor-pointer tw-group tw-ease-bezier ${
          socialSticky
            ? 'tw-translate-x-0 tw-opacity-100'
            : 'tw-translate-x-14 tw-opacity-10'
        }`}
      >
        <i
          id="chat-bubble-icon"
          className="tw-text-2xl tw-text-white fas fa-comment-smile fa-flip-horizontal"
        />
        <span
          id="chat-bubble-label"
          className="tw-uppercase tw-text-white tw-font-bold tw-text-xs"
        >
          CHAT
        </span>
      </Link>
    </div>
  )
}

export default ToTop
