'use client'
import { Link, usePathname, useRouter } from '@/navigation'
import useMeta from '@/store/meta'
import { getSearchSuggestions } from '@/utils/getSearchSuggestions'
import shouldShowCtaHideColorBar from '@/utils/navbar/shouldShowCtaHideColorBar'
import { Autocomplete, TextField } from '@mui/material'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useEffect, useState } from 'react'

export default function CCP_CTABar() {
  const pathname = usePathname()
  const __t = useTranslations()
  const router = useRouter()
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue('')
  }, [pathname])
  const { metaInfo } = useMeta()
  const keywords = getSearchSuggestions(metaInfo)
  const onSubmit = (e) => {
    e.preventDefault()
    if (value.length < 3) return
    // close autocomplete popper
    document.getElementById('cta-search-autocomplete').blur()
    router.push('/search?s=' + value)
  }
  let ctaMenu = [
    {
      icon: 'far fa-stethoscope',
      label: __t('find-doctors'),
      link: '/doctor',
      data: 'FIND_DOCTOR',
    },
    {
      icon: 'far fa-calendar-alt',
      label: __t('appointment'),
      link: '/appointment',
      data: 'APPOINTMENT',
    },
    {
      icon: 'far fa-comment-alt-smile',
      label: __t('contact'),
      link: '/contact',
      data: 'CONTACT',
    },
  ]
  ctaMenu = ctaMenu.filter(
    (menu) => !pathname.includes(menu.link) || menu.link === '/appointment'
  )

  return (
    <div
      id="top-cta-bar"
      className={`tw-fixed tw-z-50  tw-w-full tw-bg-bgh-blue-alpha/80 tw-backdrop-blur-[8px]  tw-flex tw-justify-between tw-transition-all tw-duration-200 tw-delay-100  tw-ease-bezier ${
        shouldShowCtaHideColorBar(pathname)
          ? 'tw-top-0 tw-opacity-100'
          : 'tw--top-[36px] tw-opacity-0'
      }  `}
    >
      {/* Left */}
      <form
        onSubmit={onSubmit}
        className="tw-w-full sm:tw-flex tw-hidden tw-items-center tw-justify-between"
      >
        {/* Search field */}
        <Autocomplete
          forcePopupIcon={false}
          id="cta-search-autocomplete"
          disableClearable={value !== null}
          clearOnBlur={true}
          clearOnEscape={true}
          onChange={(event, newValue) => router.push(`/search?s=${newValue}`)}
          className="!tw-pt-0 [&_.MuiAutocomplete-endAdornment]:!tw-px-6"
          fullWidth
          options={keywords?.map((option) => option)}
          renderInput={(params) => (
            <TextField
              {...params}
              id="cta-search"
              hiddenLabel
              fullWidth
              variant="standard"
              placeholder={__t('search')}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) => setValue(e.target.value)}
              minLength={3}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                className: '!tw-pt-0.5',
                startAdornment: (
                  <i
                    className={`fa fa-search tw-duration-200 ${
                      isFocused ? ' tw-text-primary' : ' tw-text-primary/20'
                    } ltr:tw-mr-2 rtl:tw-ml-2 tw-mb-0.5`}
                  />
                ),
                disableUnderline: true,
              }}
              value={value || ''}
              className="!tw-px-4 [&_input]:!tw-text-bgh-blue [&_input]:placeholder:tw-uppercase [&_input]:placeholder:tw-font-bold [&_input]:placeholder:tw-text-primary hover:[&_input]:placeholder:tw-text-primary/50 tw-duration-200"
            />
          )}
        />
        {/* Button */}
        <button
          className={`tw-flex tw-items-center tw-py-1 tw-bg-primary hover:tw-bg-bgh-blue tw-duration-500 tw-rounded-full tw-px-8 ${value && value.length > 2 ? 'tw-opacity-100' : 'tw-opacity-0'}`}
        >
          <i
            className={`fa fa-arrow-right tw-text-white tw-duration-200 tw-delay-100 ${value && value.length > 2 ? 'tw-translate-x-0 tw-opacity-100' : '-tw-translate-x-2 tw-opacity-0'}`}
          />
        </button>
      </form>
      {/* Right */}
      <div className="tw-flex tw-items-center tw-flex-none max-sm:tw-w-full">
        <div className="tw-relative tw-w-16 tw-h-full max-sm:tw-hidden rtl:tw-rotate-180">
          <Image
            alt="CTA Curve"
            src="https://static.bangkokhospital.com/uploads/assets/images/blue-header-curve.svg"
            fill="true"
            className="tw-object-right tw-object-contain"
          />
        </div>
        <nav
          className={`tw-flex tw-bg-primary tw-h-full max-sm:tw-w-full tw-overflow-x-auto tw-max-w-screen`}
        >
          {ctaMenu.map((x, i) => (
            <Link
              prefetch={false}
              data-bhq-cta={`CLICK_TOP_${x.data}`}
              key={`cta-menu-${i}`}
              href={x.link}
              className={`[&_*]:!tw-text-white [&_*]:!tw-font-bold tw-flex tw-items-center tw-justify-center  
              hover:tw-bg-bgh-blue-alpha/10 tw-duration-500 max-sm:tw-py-2 tw-border-bgh-blue-alpha/20 ltr:tw-border-r rtl:tw-border-l tw-whitespace-nowrap tw-flex-1 tw-space-x-2 md:tw-px-6 sm:tw-px-4 tw-px-2`}
            >
              <i className={`${x.icon}  tw-text-xs `} />
              <h6 className="tw-text-[3vw] xs:tw-text-xs tw-uppercase !tw-line-clamp-1">
                {x.label}
              </h6>
            </Link>
          ))}
        </nav>
      </div>
    </div>
  )
}
