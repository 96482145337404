'use client'
import TouchRipple from '@/components/main/element/TouchRipple'
import useMeta from '@/store/meta'
import { useLocale } from 'next-intl'

const PhoneCTA = () => {
  const locale = useLocale()
  const isTh = locale === 'th'
  const { metaInfo } = useMeta()
  return (
    <TouchRipple
      prefetch={false}
      href={`tel:${isTh ? '1719' : metaInfo?.phone_number_to_call}`}
      ariaLabel={isTh ? 'โทร' : metaInfo?.phone_number_to_call}
      className="md:tw-flex tw-hidden tw-items-center tw-space-x-1  tw-px-4 tw-h-full tw-group"
    >
      <i className="fa fa-phone tw-text-bgh-red group-hover:tw-animate-wiggle" />
      <h6
        className="group-hover:ltr:tw-translate-x-1 group-hover:rtl:-tw-translate-x-1  tw-duration-200"
        style={{ direction: 'ltr' }}
      >
        {isTh ? (
          <span className="tw-text-2xl tw-font-satoshiRealBold">1719</span>
        ) : (
          <>
            <span className="tw-text-bgh-red">+662</span> 310 3000
          </>
        )}
      </h6>
    </TouchRipple>
  )
}

export default PhoneCTA
