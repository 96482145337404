'use client'

import { Link } from '@/navigation'
import useAuth from '@/store/auth'
import { stringAvatar } from '@/utils/getStringAvatar'
import modifyUserMenu from '@/utils/user/modifyUserMenu'
import {
  Avatar,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
} from '@mui/material'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import SCP_CoinDisplay from '../b-point/widgets/CoinDisplay'
import SCP_CoinIcon from '../b-point/widgets/CoinIcon'

const CCP_UserMenu = () => {
  const { userMe, isUserMeLoading } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const __t = useTranslations()

  let menu = useAuth.getState().myAccountMenu.map((item) => {
    return {
      ...item,
      title: __t(item.title),
    }
  })

  menu = modifyUserMenu(menu, userMe)

  /**
   * If loading user, return a loading skeleton
   */
  if (isUserMeLoading) {
    return (
      <div className="tw-items-center tw-whitespace-nowrap tw-p-4 sm:tw-flex">
        <Skeleton
          variant="circular"
          width={28}
          height={28}
          className="tw-m-0 tw-p-0"
        />
      </div>
    )
  }
  /**
   * If userMe is not null, return the user menu
   */
  if (!userMe) {
    return (
      <Link prefetch={false} href="/auth/login">
        <ListItemButton
          component="div"
          className="menu-button tw-group tw-h-full tw-px-6"
          aria-label="user menu"
        >
          <i className="far fa-circle-user tw-text-primary group-hover:tw-text-bgh-blue group-hover:tw-animate-wiggle tw-duration-200 tw-min-w-3" />
        </ListItemButton>
      </Link>
    )
  }
  /**
   * User Menu, if logged in
   */

  return (
    <div className="tw-items-center tw-whitespace-nowrap sm:tw-flex">
      <Tooltip title={`Account settings`}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className="tw-p-4"
        >
          <Avatar
            sx={{
              width: 28,
              height: 28,
              bgcolor: 'rgb(0,45,115)',
              fontSize: 18,
            }}
            className="tw-fle tw-items-center tw-justify-center"
          >
            <span className="tw-leading-none tw-uppercase">
              {stringAvatar(userMe.full_name || '')?.children}
            </span>
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.12))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 16,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        {menu.map((item, i) => (
          <Link
            prefetch={false}
            key={i}
            href={item.path}
            passHref={true}
            target={item.target}
          >
            <MenuItem key={item.title} className={'tw-py-4'}>
              <ListItemIcon>
                {item?.activeSlug === 'b-point' ? (
                  <SCP_CoinIcon level="min" className="tw-size-8" />
                ) : (
                  <div className="tw-w-8 tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-bgh-blue-alpha">
                    <i
                      aria-hidden="true"
                      className={`${item.icon} tw-text-primary`}
                    />
                  </div>
                )}
              </ListItemIcon>
              <span className="tw-text-primary tw-font-bold tw-ml-2">
                {item.title}
              </span>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </div>
  )
}

export default CCP_UserMenu
