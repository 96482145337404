import { Link, usePathname } from '@/navigation'
import { ListItemButton } from '@mui/material'

const SearchButton = () => {
  const isHomepage = usePathname() === '/'
  return (
    <Link
      prefetch={false}
      href="/search"
      aria-label="Search"
      className={`tw-transition-all tw-duration-200 tw-h-full ${isHomepage ? 'tw-w-[48px]' : 'tw-w-0 tw-opacity-0'}`}
    >
      <ListItemButton
        component="div"
        className="menu-button tw-group tw-h-full  tw-px-6"
      >
        <i className="far fa-search tw-text-primary group-hover:tw-text-bgh-blue group-hover:tw-animate-wiggle tw-duration-200 tw-min-w-3" />
      </ListItemButton>
    </Link>
  )
}

export default SearchButton
