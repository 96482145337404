export const getSearchSuggestions = (metaInfo) => {
  if (!metaInfo) return []
  return metaInfo
    ? Array.isArray(metaInfo?.search_suggestions)
      ? [
          ...new Set(
            metaInfo?.search_suggestions
              ?.map((x) => x.toString())
              .filter(Boolean)
          ),
        ]
      : [
          ...new Set(
            Object.values(metaInfo?.search_suggestions)
              ?.map((x) => x.toString())
              .filter(Boolean)
          ),
        ]
    : []
}
