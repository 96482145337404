import { create } from 'zustand'

const useGlobal = create(() => ({
  isShowMobileMenu: false,
  successModalContent: false,
  isShowSelectCenterModal: false,
  centerModalType: '',
  simpleModalTitle: null,
  simpleModalDescription: null,
  simpleModalChildren: null,
  simpleModalWrapperClass: '',
  buttonModalDetail: null,
  isShowDoctorFilter: false,
  isShowSearchFilter: false,
  isShowHomepagePopup: false,
  isShowGuestSnackbar: false,
  confirmationDialog: null,
}))

export default useGlobal
