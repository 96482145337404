'use client'

import { Link } from '@/navigation'
import useMeta from '@/store/meta'
import { ListItemButton } from '@mui/material'
import { useLocale } from 'next-intl'

const MobileCTA = () => {
  const locale = useLocale()
  const isTh = locale === 'th'
  const { metaInfo } = useMeta()
  return (
    <>
      <Link
        prefetch={false}
        href={`tel:${isTh ? '1719' : metaInfo?.phone_number_to_call}`}
        aria-label={isTh ? 'โทร' : metaInfo?.phone_number_to_call}
      >
        <ListItemButton
          component="div"
          className="menu-button "
          aria-label={isTh ? 'โทร' : metaInfo?.phone_number_to_call}
        >
          <i className="fas fa-phone" />
        </ListItemButton>
      </Link>
      <Link prefetch={false} href="/search" aria-label="search">
        <ListItemButton
          component="div"
          className="menu-button"
          aria-label="search"
        >
          <i className="fas fa-magnifying-glass" />
        </ListItemButton>
      </Link>
    </>
  )
}

export default MobileCTA
