'use client'

import useGlobal from '@/store/global'
import { Modal } from '@mui/material'

export default function CCP_HomePopUp({ popUpData }) {
  const { isShowHomepagePopup } = useGlobal()

  return (
    <>
      <Modal
        id={popUpData?.popup_name}
        open={isShowHomepagePopup}
        onClose={() => useGlobal.setState({ isShowHomepagePopup: false })}
        aria-labelledby="home-modal"
        disableAutoFocus={true}
        closeAfterTransition
      >
        <>
          {/* Close Icon */}
          <button
            onClick={() => useGlobal.setState({ isShowHomepagePopup: false })}
            className="tw-absolute tw-right-0 tw-top-0 tw-z-10 tw-flex tw-size-12 tw-items-center tw-justify-center tw-rounded-bl-xl tw-bg-bgh-gray-primary hover:tw-bg-bgh-gray-primary-hover tw-duration-200"
          >
            <i
              aria-hidden="true"
              className="far fa-times tw-text-center tw-text-xl tw-text-white tw-duration-100 "
            />
          </button>
          <div className="mui-modal sm:!tw-max-w-xl">
            <div
              className="entry-content [&_:last-child]:!tw-mb-0"
              dangerouslySetInnerHTML={{
                __html: popUpData?.seo_blocking_popup_content,
              }}
            />
          </div>
        </>
      </Modal>
    </>
  )
}
