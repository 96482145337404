import isEmpty from 'lodash.isempty'

export default function submitSearchParams(
  searchParams,
  push,
  pathname,
  valueObj,
  removeParams = ['paged'],
  hash = ''
) {
  if (isEmpty(valueObj)) return
  const params = new URLSearchParams(searchParams)
  if (valueObj) {
    Object.keys(valueObj).forEach((key) => {
      if (valueObj[key]) {
        // if value is Invalid Date
        if (valueObj[key] === 'Invalid Date') return
        params.set(key, valueObj[key])
      } else {
        params.delete(key)
      }
    })
  }
  removeParams.forEach((param) => {
    params.delete(param)
  })
  if (hash) {
    push(`${pathname}?${params.toString()}${hash}`)
  } else {
    push(`${pathname}?${params.toString()}`)
  }
}
