import { Link } from '@/navigation'
import { useTranslations } from 'next-intl'
import ConfettiExplosion from 'react-confetti-explosion'
import SCP_CoinIcon from '../widgets/CoinIcon'

export default function SCP_SnackbarPointLogin({ text = '' }) {
  const __t = useTranslations()
  if (!text) return null
  return (
    <div className="tw-flex tw-items-center tw-space-x-2">
      <ConfettiExplosion number={4000} />
      <div className="tw-flex tw-items-center tw-space-x-2">
        <SCP_CoinIcon level="min" />
        <span>{text}</span>
      </div>
      <Link
        prefetch={false}
        href="/my-account/b-point?tab=point-history"
        className="tw-flex-none hover:tw-bg-white/10 tw-duration-200 tw-px-2.5 tw-py-0.5 tw-h-min tw-border tw-border-white/50 tw-rounded-full"
      >
        {__t('view')}
      </Link>
    </div>
  )
}
