'use client'
import useGlobal from '@/store/global'
import { Modal } from '@mui/material'
import SimpleButton from '../button/SimpleButton'
import { useTranslations } from 'next-intl'

const ButtonModal = () => {
  const __t = useTranslations()
  const { buttonModalDetail } = useGlobal()
  function handleClose() {
    useGlobal.setState({
      buttonModalDetail: null,
    })
  }

  return (
    <Modal
      open={buttonModalDetail ? true : false}
      onClose={handleClose}
      aria-labelledby="center-modal"
      disableAutoFocus={true}
      closeAfterTransition
    >
      <>
        <div className="mui-modal sm:!tw-min-w-[100px] sm:!tw-max-w-sm !tw-p-0">
          <p className="tw-p-5">{buttonModalDetail}</p>
          <div className="tw-border-t tw-p-5 tw-bg-bgh-gray-light">
            <div onClick={() => handleClose()} className="tw-ml-auto tw-w-min">
              <SimpleButton label={__t('close')} />
            </div>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default ButtonModal
