'use client'
import useGlobal from '@/store/global'
import { Modal } from '@mui/material'
import { useLocale, useTranslations } from 'next-intl'
import SimpleButton from '../button/SimpleButton'
import { usePathname } from 'next/navigation'

const SuccessModal = () => {
  const __t = useTranslations()
  const pathName = usePathname()
  const locale = useLocale()
  const { successModalContent } = useGlobal()
  function handleClose() {
    useGlobal.setState({
      successModalContent: false,
    })
    // Assign query + Hard Refresh page to reset field
    window.location.href = `${pathName}?submitted=true`
  }

  return (
    <Modal
      open={successModalContent}
      onClose={handleClose}
      aria-labelledby="center-modal"
      disableAutoFocus={true}
      closeAfterTransition
    >
      <>
        <div className="mui-modal !tw-p-0 sm:!tw-max-w-xl">
          {/* Message */}
          <div className="tw-p-8 tw-flex tw-items-center tw-flex-col tw-space-y-6">
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{ __html: successModalContent }}
            />
          </div>
          {/* Button */}
          <div className="tw-flex tw-justify-end tw-p-4 tw-border-t tw-bg-bgh-gray-light">
            <div onClick={() => handleClose()}>
              <SimpleButton label={__t('close')} />
            </div>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default SuccessModal
